export default class Constants {
  static API_BASE = "/api/v1";

  static authExcludeApiPaths = [
    "/login",
    "/signup",
    "/account_created",
    "/reset_password",
    "/set_new_password",
  ];

  static currencyFormat = "0,0.00";

  static btcFormat = "0,0.00000000";

  static backOfficeAuth = ["BornfreeAdmin"];

  static merchantAuth = ["Merchant", "Tenant"];

  static exportAllPageSize = parseInt(1000000000);

  static transactionStatusEnum = [
    "Pending",
    "Successful",
    "Failed_01",
    "Failed_02",
    "Processed",
    "Created",
    "Expired",
    "Failed",
    "Processing",
    "Declined",
  ];

  static paymentModes = {
    1: "Bank transfer",
    2: "Card",
    3: "MobileMoney",
    4: "Paypal",
  };

  static paymentActivities = {
    1: "Credit",
    2: "Debit",
  };

  static invoiccePaymentEnum = {
    1: "Paid",
    2: "Issued",
    3: "Due",
    4: "Partially Paid",
  };
}
