/* eslint-disable no-unreachable */
import { Web, Constants } from "@/components/util";
import store from "@/store/index.js";
import { Log } from "@/components/util";

export default class LoginService {
  static loginUser(userDetails, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + "/Account/login",
      userDetails,
      successHandler,
      errorHandler
    );
  }

  static resetPassword(userDetails, successHandler, errorHandler) {
    Web.post(
      Constants.API_BASE + "/Account/reset-password",
      userDetails,
      successHandler,
      errorHandler
    );
  }

  static confirmEmail(userId, code, successHandler, errorHandler) {
    Web.get(
      // Account/confirm-email?userId=www&code=wwwww
      Constants.API_BASE +
        "/Account/confirm-email?userId=" +
        userId +
        "&code=" +
        code,
      successHandler,
      errorHandler
    );
  }

  static getRefreshToken(refreshToken, successHandler, errorHandler) {
    //
    Web.postRefreshToken(
      Constants.API_BASE + "/Account/refresh-token",
      "",
      refreshToken,

      successHandler,
      errorHandler
    );
  }

  // static replaceToken(refreshToken) {
  // 	LoginService.getRefreshToken(
  // 		refreshToken,

  // 		(response) => {
  // 			const data = response.data.data;
  // 			store.commit("authToken/apiToken", data.jwToken);
  // 			store.commit("authToken/refreshToken", data.refreshToken)
  // 			Log.info("tokenData:" + JSON.stringify(data))
  // 		},
  // 		(error) => {
  // 			Log.info("Tokenerror: " + error)
  // 		}
  // 	)
  // }
  static refreshToken = () => {
    LoginService.getRefreshToken(
      store.getters["authToken/refreshToken"],
      (response) => {
        const data = response.data.data;
        store.commit("authToken/apiToken", data.jwToken);
        store.commit("authToken/refreshToken", data.refreshToken);

        Log.info("refreshAuth: " + JSON.stringify(response));
      },
      (error) => {
        Log.info("Tokenerror: " + error);
      }
    );
  };

  static handleSuccessfulLogin(response) {
    const data = response;
    Log.info("below is data");
    Log.info(data);
    store.commit("authToken/apiToken", data.jwToken ? data.jwToken : null);
    store.commit("authToken/isVerified", data.isVerified);
    store.commit("authToken/userId", data.id);
    store.commit("authToken/tenantId", data.tenantId);
    store.commit("authToken/email", data.email);
    store.commit("authToken/roles", data.roles);
    store.commit("authToken/firstName", data.firstName);
    store.commit("authToken/lastName", data.lastName);
    store.commit("authToken/hasPin", data.hasPin);
    store.commit("authToken/dob", data.dob ? data.dob : new Date("01/01/1000"));
    store.commit(
      "authToken/phoneNumber",
      data.phoneNumber ? data.phoneNumber : ""
    );
    store.commit(
      "authToken/companyName",
      data.companyName ? data.companyName : "Unverified"
    );
    store.commit(
      "authToken/countryName",
      data.countryName ? data.countryName : ""
    );
    // store.commit("authToken/companyName", null);
    store.commit("authToken/isProfileUpdated", data.isProfileUpdated);
    store.commit("authToken/isPhoneNumberVerified", data.isPhoneNumberVerified);
    store.commit("authToken/isKycDone", data.isKycDone);
    store.commit("authToken/currency", data.currency || "NGN");
    if (window.location.origin === "http://3.16.1.81:444") {
      store.commit("SET_ENVIRONMENT", "test");
    } else {
      store.commit("SET_ENVIRONMENT", data.environment.toLowerCase());
    }
    //commit refreshToken
    store.commit(
      "authToken/refreshToken",
      data.refreshToken ? data.refreshToken : null
    );
    store.dispatch("bankDetails/getBanks");
    if (window.location.origin === "http://3.16.1.81:444") {
      window.location.replace("http://3.16.1.81:444/dashboard/recent-invoice");
    } else {
      window.location.replace(
        process.env.VUE_APP_URL + "/dashboard/recent-invoice"
      );
    }
  }

  static handleLogout() {
    localStorage.clear();
    if (window.location.origin === "http://3.16.1.81:444") {
      window.location.href = "/";
      return;
    }
    var createGuest = require("cross-domain-storage/guest");
    var bazStorage = createGuest(process.env.VUE_APP_BASE_URL);
    window.opener.location.href = process.env.VUE_APP_BASE_URL + "/logout";
    bazStorage.close();

    window.close();
  }
}
