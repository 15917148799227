import Test from "@/views/Test.vue";
import Login from "@/views/login/Login.vue";

import { Constants } from "@/components/util";

export default [
  {
    path: "/auto-login/:id",
    name: "External login",
    meta: {
      skipAuth: true,

      title: "Katsu",
      metaTags: [
        {
          name: "description",
          content: "KatsuFinance account",
        },
        {
          property: "og:description",
          content: "KatsuFinance account",
        },
      ],
    },
    component: () => import("@/views/ExternalLogin.vue"),
  },
  // {
  //   path: "/",
  //   redirect: "/login",
  //   name: "Home",
  //   meta: {
  //     skipAuth: true,
  //   },
  //   component: Home,
  //   children: [
  //     {
  //       path: "/signup",
  //       name: "Signup",
  //       meta: {
  //         skipAuth: true,

  //         title: "Sign Up - KatsuFinance",
  //         metaTags: [
  //           {
  //             name: "description",
  //             content: "Sign Up on KatsuFinance account",
  //           },
  //           {
  //             property: "og:description",
  //             content: "Sign Up on KatsuFinance account",
  //           },
  //         ],
  //       },
  //       component: Signup,
  //     },
  {
    path: "/",
    name: "Login",

    component: Login,
    meta: {
      skipAuth: true,
      title: "Login - KatsuFinance",
      metaTags: [
        {
          name: "description",
          content: "Login in KatsuFinance account",
        },
        {
          property: "og:description",
          content: "Login in KatsuFinance account",
        },
      ],
    },
    beforeEnter: () => {
      if (
        window.location.origin !== "http://3.16.1.81:444" &&
        window.location.origin !== "http://localhost:8080"
      ) {
        window.location.href = "https://app.katsupay.com";
      }
    },
  },

  //     {
  //       path: "/reset_password",
  //       name: "ResetPassword",
  //       meta: {
  //         skipAuth: true,
  //       },
  //       component: () => import("@/views/forgotPassword/ResetPassword.vue"),
  //     },

  //     {
  //       path: "/account_created",
  //       name: "Account Created",
  //       meta: {
  //         skipAuth: true,
  //       },
  //       component: () => import("@/views/signup/AccountCreated.vue"),
  //     },

  //     {
  //       path: "/set_new_password",
  //       name: "SetNewPassword",
  //       meta: {
  //         skipAuth: true,
  //       },
  //       component: () => import("@/views/forgotPassword/SetNewPassword.vue"),
  //     },
  //   ],
  //   beforeEnter: () => {
  //     if (store.getters["authToken/loggedIn"]) {
  //       return { name: "MainApp" };
  //     }
  //   },
  // },
  {
    path: "/preview/invoice/:id",
    name: "Invoice Preview",
    meta: {
      skipAuth: true,

      title: "Preview - KatsuFinance",
      metaTags: [
        {
          name: "description",
          content: "Invoice preview on KatsuFinance account",
        },
        {
          property: "og:description",
          content: "Invoice preview on KatsuFinance account",
        },
      ],
    },
    component: () => import("@/views/payments/InvoicePreview.vue"),
  },
  {
    path: "/make-payment/:id",
    name: "Make Payment",
    meta: {
      skipAuth: true,

      title: "Make Payment - KatsuFinance",
      metaTags: [
        {
          name: "description",
          content: "Make Payment on KatsuFinance account",
        },
        {
          property: "og:description",
          content: "Make Payment on KatsuFinance account",
        },
      ],
    },
    component: () => import("@/views/payments/PaymentMethods.vue"),
  },
  {
    path: "/payment-success",
    name: "Payment success",
    meta: {
      skipAuth: true,

      title: "Payment success - KatsuFinance",
      metaTags: [
        {
          name: "description",
          content: "Payment success on KatsuFinance account",
        },
        {
          property: "og:description",
          content: "Payment success on KatsuFinance account",
        },
      ],
    },
    component: () => import("@/views/payments/PaymentSuccessful.vue"),
  },
  {
    path: "/test556874M2N4DK23",
    name: "Test",
    meta: {
      skipAuth: true,
    },
    component: Test,
  },
  // {
  // 	path: "/semanticUi",
  // 	name: "SemanticUi",
  // 	meta: {
  // 		skipAuth: true,
  // 	},
  // 	component: () => import("@/views/semanticUi.vue")
  // },

  // {
  //   path: "/verification_code",
  //   name: "Verification Code",
  //   meta: {
  //     skipAuth: true,
  //   },
  //   component: () => import("@/views/otp/VerificationCode.vue"),
  // },
  // {
  //   path: "/api/v1/account/confirm-email",
  //   name: "Confirm Email",
  //   meta: {
  //     skipAuth: true,
  //   },
  //   component: () => import("@/views/redirect/ConfirmEmailRedirect.vue"),
  // },
  // {
  //   path: "/api/v1/account/reset-password",
  //   name: "Reset Password",
  //   meta: {
  //     skipAuth: true,
  //     title: "Reset password - KatsuFinance",
  //     metaTags: [
  //       {
  //         name: "description",
  //         content: "Reset password in KatsuFinance account",
  //       },
  //       {
  //         property: "og:description",
  //         content: "Reset password in KatsuFinance account",
  //       },
  //     ],
  //   },
  //   redirect: "/set_new_password",
  //   component: () => import("@/views/redirect/ConfirmEmailRedirect.vue"),
  // },
  // {
  // 	path: "/letters",
  // 	name: "Letters",
  // 	meta: {
  // 		skipAuth: true,
  // 	},
  // 	component: () => import("@/views/Letters.vue")
  // },

  // {
  // 	path: "/business_details",
  // 	name: "Business Details",
  // 	component: () => import("@/views/businessDetails/BusinessDetails.vue")
  // },

  {
    path: "/main_app",
    redirect: "/dashboard",

    //remember to remove this
    meta: {
      auth: Constants.merchantAuth,
    },
    name: "MainApp",
    component: () => import("@/views/MainApp.vue"),
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",

        component: () => import("@/views/main/BaseTemplate.vue"),
        children: [
          {
            path: "",
            name: "Dashboard",
            redirect: "/dashboard/recent-invoice",

            component: () => import("@/views/main/dashboard/Dashboard.vue"),
            children: [
              {
                path: "recent-invoice",
                name: "Dashboard Recent Invoic",

                component: () =>
                  import("@/views/main/dashboard/RecentInvoice.vue"),
              },
              {
                path: "recent-payment",
                name: "Dashboard Recent Paymen",

                component: () =>
                  import("@/views/main/dashboard/RecentPayments.vue"),
              },
              {
                path: "due-invoices",
                name: "Dashboard Due Invoic",

                component: () =>
                  import("@/views/main/dashboard/DueInvoices.vue"),
              },
            ],
            meta: {
              title: "Dashboard - KatsuFinance",
              metaTags: [
                {
                  name: "description",
                  content: "Dashboard in KatsuFinance account",
                },
                {
                  property: "og:description",
                  content: "Dashboard in KatsuFinance account",
                },
              ],
            },
          },
        ],
      },
      {
        path: "/counterparties",
        name: "Counterparties",

        component: () => import("@/views/main/BaseTemplate.vue"),
        children: [
          {
            path: "",
            name: "Counterparties",
            redirect: "/counterparties/all",
            component: () =>
              import("@/views/main/counterparties/CounterParties.vue"),
            children: [
              {
                path: "all",
                name: "All Counterparties",

                component: () =>
                  import("@/views/main/counterparties/AllCounterParties.vue"),
              },
              {
                path: "group",
                name: "Counterparties Group",

                component: () =>
                  import("@/views/main/counterparties/CounterPartiesGroup.vue"),
              },
            ],
          },
          {
            path: "add-counterparty",
            name: "Add Counterparties",

            component: () =>
              import("@/views/main/counterparties/AddCounterparty.vue"),
          },
          {
            path: "info/:id",
            name: "Counterparties Info",

            component: () =>
              import(
                "@/views/main/counterparties/counterpartinfo/CounterpartInfo.vue"
              ),
            children: [
              {
                path: "/counterparties/info/:id/details",
                name: "Counterparties Details",

                component: () =>
                  import(
                    "@/views/main/counterparties/counterpartinfo/CounterpartDetails.vue"
                  ),
              },
              {
                path: "/counterparties/info/:id/bank-account",
                name: "Counterparties Bank Acc",

                component: () =>
                  import(
                    "@/views/main/counterparties/counterpartinfo/CounterpartBankAccount.vue"
                  ),
              },
              {
                path: "/counterparties/info/:id/invoices",
                name: "Counterparties Payment Orders",

                component: () =>
                  import(
                    "@/views/main/counterparties/counterpartinfo/CounterpartInvoices.vue"
                  ),
              },
              {
                path: "/counterparties/info/:id/transactions",
                name: "Counterparties Transactions",

                component: () =>
                  import(
                    "@/views/main/counterparties/counterpartinfo/CounterpartTransactions.vue"
                  ),
              },
            ],
          },
        ],
        meta: {
          title: "Counterparties - KatsuFinance",
          metaTags: [
            {
              name: "description",
              content: "Counterparties in KatsuFinance account",
            },
            {
              property: "og:description",
              content: "Counterparties in KatsuFinance account",
            },
          ],
        },
      },
      {
        path: "/reports",
        name: "Reports Home",

        component: () => import("@/views/main/BaseTemplate.vue"),
        children: [
          {
            path: "",
            name: "Reports",
            redirect: "/reports/account-statement",

            component: () => import("@/views/main/reports/Reports.vue"),
            children: [
              {
                path: "select-account",
                name: "Reports Select ",
                component: () =>
                  import("@/views/main/reports/SelectAccount.vue"),
              },
              {
                path: "account-statement",
                name: "Reports Statement",
                component: () =>
                  import("@/views/main/reports/AccountStatement.vue"),
              },
              {
                path: "invoice-report",
                name: "In-voice Reports",
                component: () =>
                  import("@/views/main/reports/InvoiceReport.vue"),
              },
              {
                path: "counterparty-statement",
                name: "Statement Reports",
                component: () =>
                  import("@/views/main/reports/CounterpartyStatement.vue"),
              },
              {
                path: "payment-report",
                name: "Payment Reports",
                component: () =>
                  import("@/views/main/reports/PaymentReport.vue"),
              },
            ],
          },
        ],
        meta: {
          title: "Reports - KatsuFinance",
          metaTags: [
            {
              name: "description",
              content: "Reports in KatsuFinance account",
            },
            {
              property: "og:description",
              content: "Reports in KatsuFinance account",
            },
          ],
        },
      },
      {
        path: "/products",
        name: "Products Home",

        component: () => import("@/views/main/BaseTemplate.vue"),
        children: [
          {
            path: "",
            name: "Products",

            component: () => import("@/views/main/products/ProductsList.vue"),
          },
          {
            path: "add-product",
            name: "Add Products",

            component: () => import("@/views/main/products/AddProduct.vue"),
          },
        ],
        meta: {
          title: "Products - KatsuFinance",
          metaTags: [
            {
              name: "description",
              content: "Products in KatsuFinance account",
            },
            {
              property: "og:description",
              content: "Products in KatsuFinance account",
            },
          ],
        },
      },

      {
        path: "/invoices",
        name: "Invoices Home",

        component: () => import("@/views/main/BaseTemplate.vue"),
        children: [
          {
            path: "",
            name: "Invoices",
            redirect: "/invoices/list",

            component: () =>
              import("@/views/main/invoices/InvoicesManagement.vue"),
            children: [
              {
                path: "list",
                name: "Invoices List",

                component: () =>
                  import("@/views/main/invoices/InvoicesList.vue"),
              },
              {
                path: "/invoices/invoice-payments",
                name: "Invoices Payments",

                component: () =>
                  import("@/views/main/invoices/InvoicesPayments.vue"),
              },
              {
                path: "/invoices/recurring-invoices",
                name: "Recurring Invoices",

                component: () =>
                  import("@/views/main/invoices/RecurringInvoices.vue"),
              },
            ],
          },
          {
            path: "/invoices/new-invoice",
            name: "New Invoice",

            component: () => import("@/views/main/invoices/NewInvoice.vue"),
          },
          {
            path: "/invoices/edit-invoice/:id",
            name: "Edit Invoice",

            component: () => import("@/views/main/invoices/EditInvoice.vue"),
          },
          // {
          // 	path: "add-product",
          // 	name: "Add Products",

          // 	component: () => import("@/views/main/products/AddProduct.vue"),
          // },
        ],
        meta: {
          title: "Invoices - KatsuFinance",
          metaTags: [
            {
              name: "description",
              content: "Invoices in KatsuFinance account",
            },
            {
              property: "og:description",
              content: "Invoices in KatsuFinance account",
            },
          ],
        },
      },
      // {
      //   path: "/compliance",
      //   name: "Compliance",

      //   component: () => import("@/views/settings/compliance/Compliance.vue"),
      //   meta: {
      //     title: "Compliance - KatsuFinance",
      //     metaTags: [
      //       {
      //         name: "description",
      //         content: "Compliance in KatsuFinance account",
      //       },
      //       {
      //         property: "og:description",
      //         content: "Compliance in KatsuFinance account",
      //       },
      //     ],
      //   },
      // },
      {
        path: "/account_settings",
        name: "Account Settings",
        redirect: "/profile_settings",
        component: () => import("@/views/settings/Settings.vue"),
        children: [
          {
            path: "/profile_settings",
            name: "Profile Settings",
            component: () => import("@/views/settings/ProfileSettings.vue"),
          },
          {
            path: "/settings",
            name: "tax settings",

            component: () => import("@/views/settings/SettingIndex.vue"),
          },
          {
            path: "/security",
            name: "Security Settings",
            component: () => import("@/views/settings/Security.vue"),
          },

          {
            path: "/api_keys",
            name: "API Keys Settings",
            component: () => import("@/views/settings/APIKeys.vue"),
          },
          {
            path: "/passcode_settings",
            name: "Passcode Settings",

            component: () => import("@/views/settings/passcode/Passcode.vue"),
          },
          {
            path: "/rates_settings",
            name: "Rates Settings",

            component: () => import("@/views/settings/ConfigureRates.vue"),
          },
        ],
        meta: {
          title: "Account Settings - KatsuFinance",
          metaTags: [
            {
              name: "description",
              content: "Account Settings in KatsuFinance account",
            },
            {
              property: "og:description",
              content: "Account Settings in KatsuFinance account",
            },
          ],
        },
      },
      {
        path: "/bank-transfers",
        name: "bank transfers",

        component: () =>
          import("@/views/main/banktransfers/IndexComponent.vue"),
        children: [
          {
            path: "",
            name: "bank transfer List",
            component: () =>
              import("@/views/main/banktransfers/TransferList.vue"),
          },
          {
            path: ":name",
            name: "bank Transfer",
            component: () =>
              import("@/views/main/banktransfers/TransferIndex.vue"),
          },
        ],
        meta: {
          title: "Bank transfers - KatsuFinance",
          metaTags: [
            {
              name: "description",
              content: "Bank transfers in KatsuFinance account",
            },
            {
              property: "og:description",
              content: "Bnak transfers in KatsuFinance account",
            },
          ],
        },
      },
      {
        path: "/accounts",
        name: "accounts",

        component: () => import("@/views/main/accounts/IndexComponent.vue"),
        children: [
          {
            path: "",
            name: "account List",
            component: () => import("@/views/main/accounts/AccountsList.vue"),
          },
          {
            path: "detail/:accountId",
            name: "account details",
            component: () => import("@/views/main/accounts/AccountList.vue"),
          },
          {
            path: "open-account",
            name: "open account",
            component: () => import("@/views/main/accounts/AccountIndex.vue"),
          },
        ],
        meta: {
          title: "Account list - KatsuFinance",
          metaTags: [
            {
              name: "description",
              content: "Account list in KatsuFinance account",
            },
            {
              property: "og:description",
              content: "Account list in KatsuFinance account",
            },
          ],
        },
      },
      // {
      // 	path: "/settings",
      // 	name: "settings",

      // 	component: () => import("@/views/main/settings/SettingIndex.vue"),
      // },
      {
        path: "/virtual-accounts",
        name: "virtual",

        component: () =>
          import("@/views/main/virtualaccounts/IndexComponent.vue"),
        children: [
          {
            path: "",
            name: "virtual",
            component: () =>
              import("@/views/main/virtualaccounts/AccountsList.vue"),
          },
          {
            path: "new",
            name: "new virtual",
            component: () =>
              import("@/views/main/virtualaccounts/AccountIndex.vue"),
          },
          {
            path: "list/:bank/:accountNo/:id",
            name: "virtual List",
            component: () =>
              import("@/views/main/virtualaccounts/AccountList.vue"),
          },
        ],
        meta: {
          title: "Virtual accounts - KatsuFinance",
          metaTags: [
            {
              name: "description",
              content: "Virtual accounts in KatsuFinance account",
            },
            {
              property: "og:description",
              content: "Virtual accounts in KatsuFinance account",
            },
          ],
        },
      },
      {
        path: "/wallets",
        name: "wallets",
        component: () => import("@/views/main/wallets/Wallets.vue"),
        meta: {
          title: "Wallets - KatsuFinance",
          metaTags: [
            {
              name: "description",
              content: "Wallets in KatsuFinance account",
            },
            {
              property: "og:description",
              content: "Wallets  in KatsuFinance account",
            },
          ],
        },
      },
      {
        path: "/mobile-money",
        name: "mobile money",
        component: () =>
          import("@/views/main/mobilemoney/MobileMoneyindex.vue"),
        children: [
          {
            path: "",
            name: "mobile money List",
            component: () =>
              import("@/views/main/mobilemoney/TransferList.vue"),
          },
          {
            path: ":name",
            name: "mobile money",
            component: () =>
              import("@/views/main/mobilemoney/TransferIndex.vue"),
          },
        ],
        meta: {
          title: "Mobile Money - KatsuFinance",
          metaTags: [
            {
              name: "description",
              content: "Mobile Money in KatsuFinance account",
            },
            {
              property: "og:description",
              content: "Mobile Money in KatsuFinance account",
            },
          ],
        },
      },
      // 	],
      // },
    ],
  },
  //ends here
  {
    path: "/:catchAll(.*)",
    name: "Not found",
    component: () =>
      import(/* webpackChunkName: "notfound" */ "../views/NotFound"),
  },
];
