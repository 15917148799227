export default class Log {
  static info(msg) {
    if (process.env.NODE_ENV === "development") {
      console.info(msg);
    }
  }

  static error(err) {
    if (process.env.NODE_ENV === "development") {
      console.error(err);
    }
  }

  static warn(msg) {
    if (process.env.NODE_ENV === "development") {
      console.warn(msg);
    }
  }
}
