<template>
  <div class="h-full">
    <div class="flex flex-col">
      <div class="pb-28 w-[300px] max-w-[600px] mx-auto mt-32">
        <div class="mb-10 text-center mx-auto flex justify-center">
          <SuprBizLogo />
        </div>
        <p class="grey-5 fs-18 fw-400 mt-8 mb-4 text-center">
          {{ $t("message.loginyouraccount") }}
        </p>
        <FormErrorAlert
          @clicked="hideErrorAlert"
          v-if="showErrorAlert"
          :msg="errMsg"
        />
        <Form
          @submit="handleLogin"
          :validation-schema="schema"
          v-slot="{ errors }"
          class="flex flex-col"
        >
          <div class="mb-4">
            <div class="relative">
              <Field
                id="Email"
                name="email"
                type="text"
                v-model="userEmail"
                autocomplete="off"
                required
                class="grey-8 fs-14 fw-400 relative mt-1.5 br-8 appearance-none block w-full px-3 pt-4 border border-gray-200 focus:outline-none sm:text-sm"
                :class="{ 'is-invalid inputError': errors.email }"
              />

              <span class="floating-label z-20">
                {{ $t("message.emailaddress") }}</span
              >
            </div>
            <div class="invalid-feedback errorMsg">{{ errors.email }}</div>
          </div>

          <div class="mb-6">
            <div class="relative">
              <Field
                id="Password"
                name="password"
                type="password"
                autocomplete="off"
                v-model="userPassword"
                required
                class="grey-8 fs-14 fw-400 relative mt-1.5 br-8 appearance-none block w-full px-3 pt-4 border border-gray-200 focus:outline-none sm:text-sm"
                :class="{ 'is-invalid inputError': errors.password }"
              />
              <span class="floating-label"> {{ $t("message.password") }}</span>
              <span class="showPasswordBtn cursor-pointer">
                <div
                  class="px-3 cursor-pointer"
                  v-if="!showPassword"
                  @click="show('Password')"
                >
                  {{ $t("message.show") }}
                </div>
                <div
                  class="px-3 cursor-pointer"
                  v-else
                  @click="hide('Password')"
                >
                  {{ $t("message.hide") }}
                </div>
              </span>
            </div>
            <div class="invalid-feedback errorMsg">{{ errors.password }}</div>
          </div>

          <button
            :disabled="userEmail.length === 0 || userPassword.length === 0"
            :class="
              Object.keys(errors).length > 0 ||
              userEmail.length === 0 ||
              userPassword.length === 0
                ? 'opacity-25'
                : 'opacity-100'
            "
            class="h-52px text-white fs-16 fw-500 bluebtn br-8 flex items-center justify-center"
          >
            {{ $t("message.login") }}

            <div v-if="loading" class="h-4 w-4 ml-4 rounded-md block">
              <div class="roundLoader opacity-50 mx-auto"></div>
            </div>
          </button>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import SuprBizLogo from "@/components/svg/SuprBizLogo.vue";
import FormErrorAlert from "@/components/FormErrorAlert.vue";
import { reactive, toRefs, ref, onBeforeMount } from "vue";
// import UserInfo from "@/services/userInfo/userInfo.js";
import axios from "axios";
import { useRouter } from "vue-router";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import LoginService from "@/services/login/LoginService.js";
import { Log, Util } from "@/components/util";
import { encryptData } from "@/components/util/Crypto";

export default {
  name: "Login",
  components: {
    SuprBizLogo,

    FormErrorAlert,
    Form,
    Field,
  },
  setup() {
    onBeforeMount(() => {
      if (
        window.location.origin !== "http://3.16.1.81:444" &&
        window.location.origin !== "http://localhost:8080"
      ) {
        window.location.href = "https://app.katsupay.com";
      }
    });
    const router = useRouter();

    // const store = useStore();
    const loading = ref(false);
    const errMsg = ref("");
    const showErrorAlert = ref(false);
    const showPassword = ref(false);
    const user = reactive({
      userEmail: "",
      userPassword: "",
    });

    const schema = Yup.object().shape({
      email: Yup.string()
        .required("Email is required")
        .email("Email is invalid"),
      password: Yup.string().required("Password is required"),
    });

    const hide = (id) => {
      showPassword.value = false;
      document.getElementById(id).type = "password";
    };

    const show = (id) => {
      showPassword.value = true;
      document.getElementById(id).type = "text";
    };

    const hideErrorAlert = () => {
      showErrorAlert.value = false;
    };

    const handleLogin = (values) => {
      loading.value = true;
      Log.info(loading.value);
      Log.info("user:" + JSON.stringify(user));
      axios
        .post("https://api.katsupay.com/api/v1/Onboarding/signin", {
          email: encryptData(values.email),
          password: encryptData(values.password),
        })
        .then((response) => {
          LoginService.handleSuccessfulLogin(response.data.data);

          router.push("/dashboard/recent-invoice");

          Util.handleGlobalAlert(true, "success", response.data.message);
        })
        .catch((error) => {
          loading.value = false;

          Util.handleGlobalAlert(true, "failed", error.response.data.message);
          // errMsg.value = error.response.data.message;
          // showErrorAlert.value = true;
        });
    };

    const goToSignUp = () => {
      router.push("/signup");
    };

    const goToPasswordReset = () => {
      router.push("/reset_password");
    };

    return {
      ...toRefs(user),
      handleLogin,
      goToSignUp,
      schema,
      goToPasswordReset,
      loading,
      errMsg,
      showErrorAlert,
      hideErrorAlert,
      hide,
      show,
      showPassword,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/inputs.scss";
</style>
