export const messages = {
  dashboard: "Tableau de bord",
  searchbytype: "Rechercher ou taper",
  accounts: "Comptes",
  accountsettings: "Paramètres du compte",
  wallets: "Portefeuilles",
  mobilemoney: "Argent mobile",
  counterparty: "Contrepartie",
  counterparties: "Counaterparties",
  virtualaccounts: "Comptes virtuels",
  newvirtualaccounts: "Nouveaux comptes virtuel",
  invoices: "Facture",
  ansfers: "Transferts de banque",
  products: "Des produits",
  reports: "Rapports",
  compliance: "Conformité",
  manageaccount:
    "Gérez vos comptes, consultez les détails de la transaction et les équilibres ici",
  totalbalanceinallaccount: "Solde total dans tous les comptes",
  addaccount: "Ajouter un compte",
  editaccount: "Edit account",
  additems: "Ajouter des articles",
  lastactivity: "Dernière Activité",
  priordaybalance: "Solde de la journée précédente",
  availablebalance: "Solde disponible",
  filter: "Filtre",
  search: "Chercher",
  previous: "Précédent",
  next: "Prochain",
  banktransfer: "Banque Transférer",
  banktransfers: "Banque Transférers",
  selectcountry: "Choisissez le pays",
  accountname: "Nom du compte",
  selectbank: "Sélectionner la banque",
  openanaccount: "Ouvrir un compte",
  backtoaccounts: "Retour aux comptes",
  cancel: "Annuler",
  accept: "Accepter",
  confirmyouraccountdetails: "Confirmez les détails de votre compte",
  bankname: "Nom de banque",
  createatransfer: "Créer un transfert",
  currency: "Devise",
  inputtransactionpin: "Pin de transaction d'entrée",
  success: "Succès",
  accountcreatedsuccessfully: "Compte créé avec succès",
  continue: "Continuer",
  currentbalance: "Solde actuel",
  account: "Compte",
  reference: "Référence",
  date: "Date",
  amount: "Montant",
  status: "Statut",
  nothingtosee: "Rien à voir ici en ce moment",
  creategroup: "Créer un groupe",
  addcounterparty: "Ajouter une contrepartie",
  addcounterparties: "Ajouter une contreparties",
  allcounterparties: "Toutes les contreparties",
  group: "Groupe",
  name: "Nom",
  merchant: "Marchand",
  nonotifications: "Aucune notification",
  addtax: "Ajouter une taxe",
  areyousureyouwanttodeletecounterparty:
    "Êtes-vous sûr de vouloir supprimer cette contrepartie?",
  delete: "Effacer",
  editcounterparty: "Modifier la contrepartie",
  counterparrtname: "Nom de contrepartie",
  emailaddress: "Adresse e-mail",
  email: "E-mail",
  legalentityidentifier: "Identificateur d'entité juridique",
  isrequired: "est requis",
  address: "Adresse",
  country: "Pays",
  state: "État",
  city: "Ville",
  viewprofile: "Voir le profil",
  logout: "Se déconnecter",
  postalcode: "Code postal",
  createvirtual: "Créer un compte virtuel pour cette contrepartie",
  selectaccount: "Sélectionner le compte",
  accounttype: "Type de compte",
  counterpartytype: "Type de contrepartie",
  accountnumber: "Numéro de compte",
  counterpartyhasbeencreated: "La contrepartie a été créée",
  sendmoneytoyourcounterparties: "Envoyez de l'argent à vos contreparties",
  singletransfer: "Transfert unique",
  bulktransfer: "Transfert en vrac",
  singletransfers: "Transferts unique",
  bulktransfers: "Transferts en vrac",
  transactions: "Transactions",
  bulktransactions: "Transactions groupées",
  selectAccount: "Sélectionner le compte",
  description: "La description",
  uploadfile: "Téléverser un fichier",
  donwloadsamplefile: "Télécharger un exemple de fichier",
  transfers: "Transferts",
  done: "Done",
  addnewproduct: "Ajouter un nouveau produit",
  clicktoupload:
    "Cliquez pour télécharger ou faire glisser et déposer des fichiers ici",
  productname: "Nom de produit",
  salesprice: "Prix ​​de vente",
  productnumber: "Numéro de produit",
  selectbeneficiary: "Sélectionnez le bénéficiaire",
  beneficiaryaccount: "Compte du bénéficiaire",
  addproduct: "Ajouter le produit",
  editproduct: "Modifier le produit",
  updateproduct: "Mettre à jour le produit",
  addbeneficiary: "Ajouter un bénéficiaire",
  producthasbeenadded: "Le produit a été ajouté",
  selectareportyouwanttogenerate:
    "Sélectionnez un rapport que vous souhaitez générer",
  accountstatement: "Relevé de compte",
  paymentreport: "Rapport de paiement",
  counterpartyreport: "Rapport de contrepartie",
  invoicereport: "IRapport de facture",
  selectcorporateaccount: "Sélectionnez le compte d'entreprise",
  fromdate: "Partir de la date",
  todate: "À ce jour",
  type: "Taper",
  selectcounterparty: "Sélectionner la contrepartie",
  invoicestatus: "Statut de facture",
  businessdetails: "Détails de l'entreprise",
  bankaccount: "Compte bancaire",
  directors: "Réalisateurs",
  invoicelogo: "Logo de facture",
  uploadalogoruse: "Téléchargez un logo ou utilisez dans votre facture",
  paymentmethods: "Méthodes de payement",
  chooseyourpreferredpayment: "Choisissez vos options de paiement préférées",
  tax: "Impôt",
  choosefile: "Choisir le fichier",
  youttransfer: "Votre transfert a été traitéd",
  transfersuccess: "Succès de la transaction",
  addnewtax: "Ajouter une nouvelle taxe",
  taxname: "Nom de taxe",
  value: "Évaluer",
  default: "Défaut",
  profile: "Profil",
  personalinformation: "Renseignements personnels",
  fullname: "Nom et prénom",
  dob: "Date de naissance",
  savechanges: "Sauvegarder les modifications",
  security: "Sécurité",
  yourpasswordshould:
    "Votre mot de passe doit comporter au moins 8 caractères et contenir une majusculée, des minuscules, un numéro et un caractère spécial",
  currentpassword: "Mot de passe actuel",
  password: "Passe",
  newpassword: "Nouveau mot de passe",
  confirmpassword: "Confirmez le mot de passe",
  show: "Spectacle",
  hide: "Cacher",
  accesskeys: "Clés d'accès",
  livekeys: "Clé en direct",
  testkeys: "Clé de test",
  createpasscode: "Créer un mot de passe",
  passcode: "Mot de passe",
  authorizetransaction: "Autoriser la transaction",
  authorizing: "Autorisation",
  input2fa: "Entrez votre code 2FA pour confirmer cette transaction",
  your6digit:
    "Votre broche à 6 chiffres serait utilisée pour authentifier toutes vos transactions",
  forgotyourpin: "Vous avez oublié votre épingle? Réinitialisez-le ici",
  currentpasscode: "Mot de passe actuel",
  newpasscode: "Nouveau mot de passe",
  confirmpasscode: "Confirmer le mot de passe",
  confirm: "Confirmer",
  changepin: "Changer la broche",
  manageyourvirtual: "Gérez vos comptes virtuels ici",
  totalamount: "Montant total",
  totalpaid: "Totale payé",
  totaldue: "Totale dû",
  total: "Totale",
  payments: "Paiements",
  recurringinvoice: "Facture récurrente",
  duedate: "Date d'échéance",
  newinvoice: "Nouvelle facture",
  receivingaccount: "Réception du compte",
  product: "Produit",
  quantity: "Quantity",
  addanotheritem: "Ajouter un autre élément",
  subtotal: "Totale",
  discount: "Remise",
  addnotes: "Ajouter des notes (en option)",
  percentage: "Pourcentage",
  flat: "Appartement",
  paymentoptions: "Options de paiements",
  alsosendby: "Envoyer également par",
  save: "Sauvegarder",
  sendinvoice: "Envoyer une facture",
  invoicereminderoption: "Option de rappel de facture",
  beforeduedate: "Avant la date d'échéance",
  onduedate: "À la date d'échéance",
  afterduedate: "Après la date d'échéance",
  fourteendaysbefore: "14 jours avant",
  sevendaysbefore: "7 jours avant",
  threedaysbefore: "3 jours avant",
  threedaysafter: "3 jours après",
  sevendaysafter: "7 jours après",
  fourteendaysafter: "14 jours après",
  recenttransfers: "Transfert récent",
  totalaccountbalance: "Solde total du compte",
  dueinvoices: "Factures échues",
  totalpayments: "Paiements totaux",
  recentinvoice: "Facture récente",
  recentpayment: "Paiement récent",
  welcomeback: "Content de te revoir",
  loginyouraccount: "Connectez-vous à votre compte",
  forgotpassword: "Mot de passe oublié",
  donthave: "Vous n'avez pas de compte ?",
  signup: "S'inscrire",
  login: "Connexion",
  createanaccount: "Créer un compte",
  letsgetstarted: "Commençons",
  createaccount: "Créer un compte",
  alreadyhaveanaccount: "Vous avez déjà un compte?",
  signin: "S'identifier",
  backtomainmenu: "Retour au menu principal",
  selectcurrency: "Choisissez votre devise préférée",
  back: "Retour",
  transfertocounterparty: "Transfert à la contrepartie",
  transfertorecent: "Transfert au bénéficiaire",
  selectcounterpartytype: "Sélectionnez le type de contrepartie",
  lifetime: "Durée de vie",
  today: "Aujourd'hui",
  yesterday: "Hier",
  thisweek: "Cette semaine",
  thismonth: "Ce mois-ci",
  thisyear: "Cette année",
  paid: "Payé",
  issued: "Publié",
  all: "Tout",
  partiallypaid: "Partiellement payé",
  due: "Exigible",
  completed: "Complété",
  failed: "Manqué",
  cancelled: "Annulé",
  draft: "Brouillon",
  preview: "Aperçu",
  edit: "Éditer",
  resendinvoice: "Renvoyer la facture",
  addpayment: "Ajouter un paiement",
  stoprecurring: "Arrêtez de répéter",
  beneficiary: "Bénéficiaire",
  sendtoexisting:
    "Envoyer de l'argent à des bénéficiaires nouveaux et existants",
  choosetype:
    "Choisissez le destinataire parmi les transferts les plus récents",
  accountdetails: "Détails du comptes",
  recipienttype: "Nom du destinataire",
  selectrecipeinttype:
    "Sélectionnez un type de destinataire pour démarrer un nouveau transfert",
  invoiceid: "Numéro de facture",
  paymentid: "ID de paiement",
  paidamount: "Montant payé",
  paymentmode: "Mode de paiement",
  uploadname: "Télécharger le nom",
  uploaddate: "Date de dépôt",
  totalrecords: "Nombre total d'enregistrements",
  successcount: "Nombre de succès",
  failurecount: "Nombre d'échecs",
  edittax: "Modifier la taxe",
  updatetax: "Mettre à jour la taxe",
  deletetax: "Voulez-vous vraiment supprimer ce paramètre?",
  counterpartyrequired: "La contrepartie est requise",
  receivingaccountrequired: "Le compte destinataire est requis",
  duedaterequired: "La date d'échéance est requise",
  credittransactions: "Opérations de crédit",
  debittransactions: "Opérations de débit",
  fee: "Frais",
  settled: "Installé",
  source: "Source",
  client: "Cliente",
  virtualaccountname: "Nom du compte virtuel",
  comingsoon: "Bientôt disponible",
  totalrevenue: "Total Revenue",
  totalpayout: "Total Payout",
  revenuecount: "Revenue Count",
  payoutcount: "Payout Count",
};
